<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: pathurl }">{{
          breadcrumbName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>预留信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box info_box">
        <div class="title">预留信息</div>
        <div class="form_box">
          <div class="tips">我们的客户经理将在2个工作日内与您联系！</div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="80px"
            class="demo-ruleForm"
          >
            <el-form-item label="称呼昵称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="ruleForm.phone" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="企业名称">
              <el-input v-model="ruleForm.enterpriseName"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                type="textarea"
                v-model="ruleForm.desc"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交预约</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系电话"));
      } else {
        let reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
        if (!reg.test(this.ruleForm.phone)) {
          callback(new Error("请输入正确的联系电话"));
        }
      }
    };
    return {
      org: "",
      breadcrumbName: "",
      pathurl: "",
      ruleForm: {
        name: "",
        phone: "",
        enterpriseName: "",
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入称呼昵称", trigger: "blur" }],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.org = this.$route.query.org;
    if (this.org == "sz") {
      this.breadcrumbName = "数字化服务";
      this.pathurl = '/digitalService'
    }
    if (this.org == "jr") {
      this.breadcrumbName = "融资贷款服务";
      this.pathurl = '/finance_2'
    }
    if (this.org == "cw") {
      this.breadcrumbName = "代账报税服务";
      this.pathurl = '/finance_1'
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  background: #f5f5f5;
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  margin-top: 30px;
  padding-bottom: 40px;
}

.box {
  width: 1200px;
  background: #fff;
  margin: 0 auto;
}

.info_box {
  padding: 30px;
  border-radius: 10px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.form_box {
  width: 600px;
  margin: 0 auto;
}

.tips {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #055afe;
  margin-top: 20px;
}

.el-form {
  text-align: center;
  margin: 20px auto;
}
</style>
